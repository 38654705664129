<template>
    <div v-if="show" class="overlay active" @click.self="closeModal">
        <div class="popup popup-back">
            <div class="popup-close" @click="closeModal">&times;</div>

            <game-details class="modal-container"

                          :next-available="nextAvailable"
                          :partner-uid="partnerUid"
                          :game-id="gameId"
                          :bank-id="bankId"
                          :number="number"
                          :player-id="playerId"
                          :previous-available="previousAvailable"

                          @onPreviousClicked="onPreviousClicked()"
                          @onNextClicked="onNextClicked()"
            />
        </div>
    </div>
</template>

<script>
import GameDetails from "./Details/RealtimeGameDetails";

export default {
    props: {
        partnerUid: {
            type: String,
        },
        gameId: {
            type: Number,
        },
        bankId: {
            type: Number,
        },
        number: {
            type: Number,
        },
        playerId: {
            type: Number,
        },
        nextAvailable: {
            type: Boolean,
        },
        previousAvailable: {
            type: Boolean,
        }
    },
    data() {
        return {
            show: false
        }
    },
    methods: {
        onPreviousClicked() {
            this.closeModal();
            this.$emit('onPreviousClicked');
        },
        onNextClicked() {
            this.closeModal();
            this.$emit('onNextClicked');
        },
        openModal() {
            this.show = true;
            document.querySelector('html').classList.add('no-scroll');
        },
        closeModal() {
            this.show = false;
            document.querySelector('html').classList.remove('no-scroll');
        },
    },
    components: {GameDetails}
}
</script>

<style lang="less">
.no-scroll {
    overflow-y: hidden;
}

.modal-container {
    border-radius: 5px;
    border: 2px solid #f5f5f5;
}

.overlay {
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(10, 10, 10, .86);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 40;

    &.active {
        display: flex;
    }
}

.popup {
    width: auto;
    max-height: 100%;
    overflow: hidden;

    &-back {
        max-width: 800px;
    }

    &-close {
        height: 32px;
        width: 32px;
        flex-shrink: 0;
        border-radius: 50%;
        position: fixed;
        text-align: center;
        right: 20px;
        top: 20px;
        color: #FFF;
        font-size: 24px;
        line-height: 32px;
        cursor: pointer;
        font-weight: 600;

        &:hover {
            background-color: rgba(0, 0, 0, .5)
        }
    }

    &-button {
        width: 100%;
        cursor: pointer;
        text-align: center;
        background-color: #fff;
        border: 1px solid #dbdbdb;
        padding: 0.35em 1em;
        color: #363636;
        justify-content: center;
        white-space: nowrap;
        margin-left: -1px;
    }
}

.new-overlay {
    z-index: 30;
}

@media (max-width: 575px) {
    .popup {
        padding: 1% 2%;
    }
}

@media (max-height: 500px) {
    .modal-container {
        border-radius: 0;
    }

    .popup {
        padding: 0;
        height: 100vh;
        width: 100%;
        max-width: 100%;

        &-overflow {
            max-height: calc(100vh - 110px);
        }
    }
}
</style>
