import Vue from 'vue';

const spinsRepository = {
    /**
     * @return {Promise<T | never>}
     */
    getSpins: (params) => Vue.axios.get('spins', {params})
        .then((response) => response, (error) => console.log(error)),

    /**
     * @parameters {object} params
     * @return {Promise<T | never>}
     */
    getDetailedSpin: (partnerUid, sessionSpinId, sessionId) => Vue.axios.get('detailedSpin', {params: {partnerUid, sessionSpinId, sessionId}})
        .then((response) => response, (error) => console.log(error)),

    /**
     * @parameters spinUId
     * @return {Promise<T | never>}
     */
    searchBySpinUId: (spinUId) => Vue.axios.get('searchBySpinUId', {params: {spinUId}})
        .then((response) => response),

    /**
     * @parameters code
     * @return {Promise<T | never>}
     */
    searchByCode: (code) => Vue.axios.get('searchByCode', {params: {code}})
        .then((response) => response),
};

export default spinsRepository;
