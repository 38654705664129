<template>
    <section>
        <modal-header :items="gameHeader"/>
        <modal-header :items="playerPartnerHeader"/>
        <modal-header :items="bankSessionHeader"/>
        <modal-header :items="betWinRowHeader"/>
        <modal-header :items="gameDate"/>
    </section>
</template>

<script>
import ModalHeader from "@/components/ModalHeader.vue";

import {fromCents} from "@/helpers/balanceConverter";

export default {
    components: {ModalHeader},
    props: {
        bankId: {
            type: [String, Number],
            required: true,
        },
        partnerUid: {
            type: String,
            required: true,
        },
        number: {
            type: [String, Number],
            required: true,
        },
        currency: {
            type: String,
            required: true
        },
        gameTitle: {
            type: String,
            required: true,
        },
        bet: {
            type: [String, Number],
            required: true
        },
        win: {
            type: [String, Number],
            required: true
        },
        start: {
            type: [String],
            required: false
        },
        end: {
            type: [String],
            required: false
        }
    },
    computed: {
        gameHeader() {
            return {
                Game: this.gameTitle
            }
        },
        playerPartnerHeader() {
            return {
                Partner: this.partnerUid,
            }
        },
        bankSessionHeader() {
            return {
                Bank: `${this.bankId} (${this.currency})`,
                Number: this.number,
            }
        },
        gameDate() {
            return {
                Start: this.toLocalTime(this.start),
                End: this.toLocalTime(this.end),
            }
        },
        betWinRowHeader() {
            return {
                Bet: `${this.fromCents(this.bet)} ${this.currency}`,
                Win: `${this.fromCents(this.win)} ${this.currency}`
            }
        },
    },
    methods: {
        fromCents,
        toLocalTime(dateTime) {
            return window.moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm:ss');
        },
    }
}
</script>

<style scoped>

</style>
