<template>
    <section v-if="isLoaded">
        <section class="overflow">
            <game-details-body-headers :number="roundData.number" :win="roundData.win" :bank-id="roundData.bank_id"
                :bet="roundData.bet" :coin-total-win-amount="roundData.win" :currency="roundData.currency"
                :denom="roundData.denom" :start="roundData.started_at" :end="roundData.ended_at"
                :game-title="roundData.game_title" :partner-uid="partnerUid" />

            <game-details-body :game-id="roundData.game_id" :round-data="parsedRoundData" :tickets="tickets" />
        </section>
        <footer class="footer">
            <game-details-footer :is-replay-allowed="false" :next-available="nextAvailable"
                :previous-available="previousAvailable" @onPreviousClicked="$emit('onPreviousClicked')"
                @onNextClicked="$emit('onNextClicked')" />
        </footer>
    </section>
</template>

<script>
import GameDetailsBodyHeaders from "./RealtimeGameDetailsBodyHeaders";
import GameDetailsFooter from "./RealtimeGameDetailsFooter";
import GameDetailsBody from "./RealtimeGameDetailsBody";

import roundsRepository from "../../../repository/roundsRepository";
import { mapActions } from "vuex";

export default {
    props: {
        partnerUid: {
            type: [String],
            required: true,
        },
        bankId: {
            type: [String, Number],
            required: true,
        },
        gameId: {
            type: [String, Number],
            required: true,
        },
        number: {
            type: [String, Number],
            required: true,
        },
        playerId: {
            type: [String, Number],
            required: false,
        },
        nextAvailable: {
            type: Boolean,
            default: false,
        },
        previousAvailable: {
            type: Boolean,
            required: false,
        }
    },
    data() {
        return {
            isLoaded: false,
            roundData: {
                id: undefined,
                game_title: undefined,
                game_id: undefined,
                bank_id: undefined,
                number: undefined,
                currency: undefined,
                bank_desc: undefined,
                round_data: undefined,
                bet: undefined,
                win: undefined,
                denom: undefined,
                started_at: undefined,
                ended_at: undefined,
            },
            tickets: []
        }
    },
    computed: {
        parsedRoundData() {
            return JSON.parse(this.roundData.round_data);
        },
    },
    methods: {
        ...mapActions('loading', {
            startLoading: 'startLoading',
            stopLoading: 'stopLoading'
        }),
        fetchData() {
            this.startLoading();

            roundsRepository.getDetailedRound(
                this.partnerUid,
                this.bankId,
                this.gameId,
                this.number,
                this.playerId
            ).then(response => {
                this.roundData = response.rounds;
                this.tickets = response.tickets
                this.isLoaded = true;
            }).finally(this.stopLoading);
        },
        fetchRound() {
            return roundsRepository
                .getDetailedRound(this.partnerUid, this.bankId, this.gameId, this.number, this.playerId)
                .then(response => {
                    this.roundData = response;
                });
        },
        fetchTickets() {
            return roundsRepository
                .getTickets(this.partnerUid, this.bankId, this.gameId, this.number)
                .then(response => this.tickets = response)
        }
    },
    mounted() {
        this.fetchData();
    },
    components: {
        GameDetailsBodyHeaders,
        GameDetailsBody,
        GameDetailsFooter
    }
}
</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb;
}

.overflow {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    max-height: calc(100vh - 100px);
    background-color: #FFF;
}

.footer {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 50px;
    background-color: #f5f5f5;
    border-top: 1px solid #dbdbdb;
}
</style>
