<template>
    <section>
        <b-field expanded
                 label="Period"
                 label-position="on-border"
        >
            <b-input
                :value="periodText"
                expanded
                placeholder="Choose period"
                readonly
                @click.native="showDateTimePicker"
            />
            <p class="control">
                <b-tooltip label="Clear input">
                    <button
                        class="button is-danger"
                        @click="unselectPeriod"
                    >
                        <b-icon icon="delete"/>
                    </button>
                </b-tooltip>
            </p>
        </b-field>

        <vue-date-time-range-picker
            v-model="period"
            :active.sync="isDateTimePickerVisible"
            :locale="true"
        />
    </section>
</template>

<script>
import VueDateTimeRangePicker from 'vue-datetime-range-picker';

export default {
    props: {
        selectedPeriod: {
            type: Object,
            default() {
                return {
                    fromTs: null,
                    toTs: null,
                }
            }
        }
    },
    data() {
        return {
            isDateTimePickerVisible: false
        }
    },
    methods: {
        showDateTimePicker() {
            this.isDateTimePickerVisible = true;
        },
        getPeriodFromAsStrings() {
            return this.getTimeStampAsStrings(this.selectedPeriod.fromTs);
        },
        getPeriodToAsStrings() {
            return this.getTimeStampAsStrings(this.selectedPeriod.toTs);
        },
        getTimeStampAsStrings(timestamp) {
            return {
                local: window.moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss'),
                utc: window.moment.unix(timestamp).utc().format('YYYY-MM-DD HH:mm:ss'),
            };
        },
        unselectPeriod() {
            this.period = {};
        }
    },
    computed: {
        periodText() {
            if (Object.keys(this.selectedPeriod).length === 0) {
                return '';
            }

            return `${this.getPeriodFromAsStrings().local} - ${this.getPeriodToAsStrings().local}`;
        },
        period: {
            get() {
                return this.selectedPeriod
            },
            set(value) {
                this.$emit('update:selectedPeriod', value);
            }
        }
    },
    components: {
        VueDateTimeRangePicker
    }
}
</script>

<style scoped>

</style>
