const fromCents = balance => {
    let val = (parseInt(balance) / 100).toFixed(2);
    if (isNaN(val)) {
        return "";
    } else {
        return val;
    }
};

const toCents = balance => {
    let val = Math.floor(parseFloat(balance) * 100);
    if (isNaN(val)) {
        return "";
    } else {
        return val;
    }
};

export { fromCents, toCents };
