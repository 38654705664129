import { render, staticRenderFns } from "./RealtimeGameDetailsModal.vue?vue&type=template&id=267132fc"
import script from "./RealtimeGameDetailsModal.vue?vue&type=script&lang=js"
export * from "./RealtimeGameDetailsModal.vue?vue&type=script&lang=js"
import style0 from "./RealtimeGameDetailsModal.vue?vue&type=style&index=0&id=267132fc&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports