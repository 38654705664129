<template>
    <section>
        <game-details-modal ref="modal"

                            :previous-available="isPreviousAvailable"
                            :next-available="isNextAvailable"

                            :partner-uid="this.partnerUid"
                            :session-spin-id="this.sessionSpinId"
                            :session-id="this.sessionId"

                            @onPreviousClicked="onPreviousClicked"
                            @onNextClicked="onNextClicked"
        />

        <spa-game-details-modal ref="spaModal"
                                :previous-available="isPreviousAvailable"
                                :next-available="isNextAvailable"

                                @onPreviousClicked="onPreviousClicked"
                                @onNextClicked="onNextClicked"
        />

        <div class="box">
            <date-time-picker :selected-period.sync="period"/>

            <partner-select :selected-partner.sync="partner"
                            :required="false"
                            :allow-clear="true"
            />

            <bank-select :selected-bank.sync="bank"
                         :selected-partner="partner"
            />

            <player-auto-complete :selected-player.sync="player"
                                  :selected-partner="partner"
                                  :selected-bank="bank"
            />

            <slot-game-auto-complete :selected-game.sync="game"
            />

            <div class="block">
                <b-checkbox v-model="type.jackpot">
                    Jackpot
                </b-checkbox>
                <b-checkbox v-model="type.bonus">
                    Bonus
                </b-checkbox>
                <b-checkbox v-model="type.free">
                    Free
                </b-checkbox>
                <b-checkbox v-model="type.auto">
                    Auto
                </b-checkbox>
                <b-checkbox v-model="type.end">
                    End
                </b-checkbox>
            </div>

            <b-field grouped group-multiline>
                <b-field>
                    <b-button type="is-success" @click="searchClicked">Search</b-button>
                </b-field>
                <b-field>
                    <b-button type="is-primary" @click="searchBySpinUId">Search by SpinUid</b-button>
                </b-field>
                <b-field>
                    <b-button type="is-primary" @click="searchByCode">Search by code</b-button>
                </b-field>
            </b-field>
        </div>

        <div class="box">
            <spins-table :spins="spins"

                         :current-page="pagination.currentPage"
                         :total="pagination.total"
                         :per-page="pagination.perPage"
                         :selected-row="selectedRow"

                         @page-change="pageChange"
                         @per-page-change="perPageChange"
                         @sort="sortChange"
                         @onDetailClicked="fetchAndOpenDetailedModalFromTable"
            />
        </div>
    </section>
</template>

<script>
import {mapActions} from 'vuex';
import spinsRepository from "../repository/spinsRepository";

import BankSelect from "../components/Filters/BankSelect"
import SlotGameAutoComplete from "../components/Filters/SlotGameAutoComplete";
import PartnerSelect from "../components/Filters/PartnerSelect";
import PlayerAutoComplete from "../components/Filters/PlayerAutoComplete";
import DateTimePicker from "../components/Filters/DateTimePicker";
import SpinsTable from "../components/Spins/SpinsTable";
import GameDetailsModal from "../components/Spins/GameDetailsModal";
import SpaGameDetailsModal from "@/components/Spins/SpaGameDetailsModal";

export default {
    data() {
        return {
            partnerUid: undefined,
            sessionSpinId: undefined,
            sessionId: undefined,

            bank: null,
            partner: null,
            player: null,
            game: null,

            active: false,
            selectedRowIndex: null,

            spins: [],
            period: {
                fromTs: null,
                toTs: null,
            },
            type: {
                free: false,
                bonus: false,
                auto: false,
                jackpot: false,
                end: false,
            },
            tableFilters: {
                order: null,
                field: null
            },
            pagination: {
                perPage: 10,
                currentPage: 1,
                total: 0
            },

            showModal: false,
            spinData: {}
        }
    },
    computed: {
        paginationFilters() {
            return {
                perPage: this.pagination.perPage,
                page: this.pagination.currentPage,
            }
        },
        isNextAvailable() {
            let currentRowInTotal = (this.selectedRowIndex + 1) + (this.pagination.currentPage - 1) * this.pagination.perPage;

            return currentRowInTotal !== this.pagination.total;
        },
        isPreviousAvailable() {
            return !(this.pagination.currentPage === 1 && this.selectedRowIndex === 0);
        },
        spinDetails() {
            return {
                ...this.spinData,
                nextAvailable: this.isNextAvailable,
                previousAvailable: this.isPreviousAvailable
            }
        },
        formattedFormFilters() {
            let params = {};

            if (this.partner) {
                params.partnerUid = this.partner.partner_uid;
            }

            if (this.bank) {
                params.bankId = this.bank.id;
                params.partnerUid = this.bank.bank_partner_uid;
            }

            if (this.player) {
                params.playerId = this.player.player_id;
                params.partnerUid = this.player.partner_uid;
                params.bankId = this.player.bank_id;
            }

            if (this.game)
                params.gameId = this.game.id;

            params.auto = this.type.auto;
            params.free = this.type.free;
            params.bonus = this.type.bonus;
            params.end = this.type.end;
            params.jackpot = this.type.jackpot;

            params.from = this.period.fromTs;
            params.to = this.period.toTs;

            return params;
        },
        selectedRow() {
            return this.selectedRowIndex === null ? null : this.spins[this.selectedRowIndex];
        }
    },
    methods: {
        ...mapActions('loading', {
            startLoading: 'startLoading',
            stopLoading: 'stopLoading'
        }),
        getCurrentSpin() {
            return this.spins[this.selectedRowIndex];
        },
        async fetchSpins() {
            this.startLoading();

            spinsRepository.getSpins(this.getFilters())
                .then((response) => {
                        this.pagination = response.pagination;
                        this.spins = response.data;
                    }
                ).finally(() => this.stopLoading());
        },
        async searchBySpinUId() {
            this.$buefy.dialog.prompt({
                message: `Enter SpinUId`,
                inputAttrs: {
                    placeholder: 'TEST0001-12-68',
                },
                onConfirm: async (spinUId) => {
                    this.startLoading();

                    await spinsRepository.searchBySpinUId(spinUId)
                        .then(spinData => this.openSpaDetailed(spinData))
                        .finally(() => this.stopLoading());
                }
            })
        },
        async searchByCode() {
            this.$buefy.dialog.prompt({
                message: `Enter code`,
                inputAttrs: {
                    placeholder: 'a567js',
                },
                onConfirm: async (code) => {
                    this.startLoading();

                    await spinsRepository.searchByCode(code)
                        .then(spinData => this.openSpaDetailed(spinData))
                        .finally(() => this.stopLoading());
                }
            })
        },
        openModal() {
            const currentSpin = this.getCurrentSpin();

            this.fetchAndOpenDetailedModalFromTable(currentSpin.partner_uid, currentSpin.session_spin_id, currentSpin.session_id, this.selectedRowIndex);
        },
        async incrementCurrentSpin() {
            if ((this.selectedRowIndex + 1) === this.pagination.perPage) {
                this.pagination.currentPage++;
                await this.fetchSpins();
                this.selectedRowIndex = 0;
            } else {
                this.selectedRowIndex++;
            }
        },
        async decrementCurrentSpin() {
            if (this.selectedRowIndex === 0) {
                this.pagination.currentPage--;
                await this.fetchSpins();
                this.selectedRowIndex = this.pagination.perPage - 1;
            } else {
                this.selectedRowIndex--;
            }
        },
        openDetailed() {
            this.$refs.modal.openModal();
        },
        openSpaDetailed(spinData) {
            this.$refs.spaModal.openModal(spinData);
        },
        fetchAndOpenDetailedModalFromTable(partnerUid, sessionSpinId, sessionId, index) {
            this.selectedRowIndex = index;

            this.partnerUid = partnerUid;
            this.sessionId = sessionId;
            this.sessionSpinId = sessionSpinId;

            this.openDetailed();
        },
        pageChange(page) {
            this.pagination.currentPage = page;

            this.fetchSpins();
        },
        searchClicked() {
            this.pagination.currentPage = 1;

            this.fetchSpins();
        },
        perPageChange(perPage) {
            if (perPage > 50) {
                perPage = 50;
            } else if (perPage < 10) {
                perPage = 10;
            }

            this.pagination.perPage = perPage;
        },
        sortChange(sortBy, sortDesc) {
            this.pagination.currentPage = 1;

            this.tableFilters.sortBy = sortBy;
            this.tableFilters.sortDesc = sortDesc;

            this.fetchSpins();
        },
        getFilters() {
            return {
                ...this.tableFilters,
                ...this.paginationFilters,
                ...this.formattedFormFilters,
            };
        },
        async onPreviousClicked() {
            await this.decrementCurrentSpin();
            this.openModal();
        },
        async onNextClicked() {
            await this.incrementCurrentSpin();
            this.openModal();
        }
    },
    created() {
        this.period = {
            fromTs: window.moment().startOf('day').unix(),
            toTs: window.moment().endOf('day').unix()
        };
        this.fetchSpins();
    },
    components: {
        SpaGameDetailsModal,
        GameDetailsModal,
        SpinsTable,
        DateTimePicker,
        PlayerAutoComplete,
        PartnerSelect,
        SlotGameAutoComplete,
        BankSelect,
    },
}
</script>
