import authRepository from "../../repository/authRepository";

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || null,
        user: localStorage.getItem('user') || null,
        expiresIn: localStorage.getItem('expiresIn') || null,
    },
    getters: {
        isAuthenticated: state => !!state.token,

        getToken: state => state.token,
        getExpiresIn: state => state.expiresIn,
        getAuthUser: state => state.user,
    },
    mutations: {
        setBearerToken: (state, token) => (state.token = token),
        setAuthUser: (state, user) => (state.user = user),
        setExpiresIn: (state, expiresIn) => (state.expiresIn = expiresIn),

        clearBearerToken: (state) => (state.token = null),
        clearAuthUser: (state) => (state.user = null),
        clearExpiresIn: (state) => (state.expiresIn = null)
    },
    actions: {
        login: (state, credentials) => authRepository
            .login(credentials)
            .then(token => state.dispatch('setBearerToken', token))
            .then(() => state.dispatch('me')),

        logout: (state) => authRepository
            .logout()
            .finally(() => state.dispatch('removeData')),

        refresh: (state) => authRepository
            .refresh()
            .then(
                token => {
                    state.dispatch('setBearerToken', token);
                    state.dispatch('me');
                },
                (e) => {
                    state.dispatch('removeData');
                    throw e;
                }
            ),

        me: (state) => authRepository
            .me()
            .then(user => state.dispatch('setAuthUser', JSON.stringify(user))),

        setAuthUser: (state, user) => {
            state.commit('setAuthUser', user);
            localStorage.setItem('user', user);
        },

        setBearerToken: (state, token) => {
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let tokenPayload = JSON.parse(window.atob(base64));
            let expiresIn = tokenPayload.exp;

            state.commit('setBearerToken', token);
            state.commit('setExpiresIn', expiresIn);

            localStorage.setItem('token', token);
            localStorage.setItem('expiresIn', expiresIn);
        },

        removeData: (state) => {
            localStorage.clear();

            state.commit('clearAuthUser');
            state.commit('clearBearerToken');
            state.commit('clearExpiresIn');
        },

        checkExpiresIn: async (state) => {
            await new Promise((resolve, reject) => {
                state.getters.getExpiresIn < Math.floor(Date.now() / 1000)
                    ? state.dispatch('refresh')
                        .catch(reason => reject(reason))
                        .then(() => resolve())
                    : resolve()
            })
        },
    }
};
