<template>
    <b-field
        expanded
        label="Bank"
        label-position="on-border"
    >
        <b-select v-model="bank"
                  :disabled="isDataEmpty"
                  expanded

                  placeholder="Choose bank"
        >
            <option
                v-for="(bank) in banks"
                :key="bank.id"
                :value="bank"
            >
                {{ bank.id }}
                <template v-if="bank.description && bank.description.length > 0">
                    ({{ bank.description }})
                </template>
            </option>
        </b-select>
        <p class="control">
            <b-tooltip label="Clear input">
                <button
                    :disabled="isDataEmpty"
                    class="button is-danger"

                    @click="unselectBank()"
                >
                    <b-icon icon="delete"/>
                </button>
            </b-tooltip>
        </p>
    </b-field>
</template>

<script>
import {mapActions} from "vuex";
import backofficeRepository from '../../repository/backofficeRepository';

export default {
    props: {
        selectedBank: {
            type: Object
        },
        selectedPartner: {
            type: Object
        }
    },
    data() {
        return {
            banks: [],
        }
    },
    computed: {
        bank: {
            get() {
                return this.selectedBank;
            },
            set(value) {
                this.$emit('update:selectedBank', value);
            }
        },
        isDataEmpty() {
            return this.banks.length === 0;
        }
    },
    methods: {
        ...mapActions('loading', {
            startLoading: 'startLoading',
            stopLoading: 'stopLoading'
        }),
        getParams() {
            let params = {};

            if (this.selectedPartner) {
                params.partnerUid = this.selectedPartner.partner_uid;
            }

            return params;
        },
        fetchData() {
            this.startLoading();

            backofficeRepository.getBanks(this.getParams())
                .then(response => {
                    this.banks = response.filter(bank => bank.is_active);
                })
                .finally(() => this.stopLoading());
        },
        unselectBank() {
            this.bank = null;
        }
    },
    watch: {
        selectedPartner() {
            this.unselectBank();
            this.fetchData();
        }
    },
    mounted() {
        this.fetchData();
    }
}
</script>

<style scoped>

</style>
