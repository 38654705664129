import Vue from 'vue';
import VueRouter from 'vue-router';

import store from "./store/store";

import SpinsView from './views/SpinsView';
import Login from "./components/Login/Login";
import SlotGameDetails from "./components/Spins/Details/SlotGameDetails";
import RealtimeGameDetails from "./components/Rounds/Details/RealtimeGameDetails";
import WebView from "./views/WebView";
import ExternalView from "./views/ExternalView";
import RoundsView from "@/views/RoundsView.vue";

Vue.use(VueRouter);

const routes = [
    {
        name: 'web',
        path: '/',
        component: WebView,
        children: [
            {
                name: 'login',
                path: '/login',
                component: Login,
            },
            {
                name: 'rounds',
                path: '/rounds',
                component: RoundsView,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                name: 'spins',
                path: '/spins',
                component: SpinsView,
                meta: {
                    requiresAuth: true,
                },
            },
        ]
    },
    {
        component: ExternalView,
        name: 'external',
        path: '/external',
        children: [
            {
                name: 'detailedSpin',
                path: 'detailedSpin',
                component: SlotGameDetails,
                props(route) {
                    return route.query || {}
                }
            },
            {
                name: 'detailedRound',
                path: 'detailedRound',
                component: RealtimeGameDetails,
                props(route) {
                    return route.query || {}
                }
            },
        ]
    },
    {
        path: '*',
        redirect: '/',
    }
];

export const router = new VueRouter({
    routes,
    base: '/',
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        store.dispatch('auth/checkExpiresIn')
            .then(
                () => next(),
                () => next({path: '/login', params: {nextUrl: to.fullPath}})
            );
    } else {
        next();
    }
});
