import { render, staticRenderFns } from "./BankSelect.vue?vue&type=template&id=505042ae&scoped=true"
import script from "./BankSelect.vue?vue&type=script&lang=js"
export * from "./BankSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "505042ae",
  null
  
)

export default component.exports