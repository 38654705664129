const gamesModals = {
    201: 'Realtime/FlightsModal',
    218: 'Realtime/LuckySixModal',
    219: 'Realtime/LiveRoulette/LiveRouletteModal',
    220: 'Realtime/FlightsModal',
    221: 'Realtime/LuckySixModal',
};

export const getGameModal = gameId => {
    let gamesModal = gamesModals[gameId];

    if (gamesModal) {
        return () => import(`@/components/Spins/Games/${gamesModal}`);
    } else {
        return () => import(`@/components/Spins/Games/UnknownModal`);
    }
};

export default { getGameModal };
