export default {
    namespaced: true,
    state: {
        height: 0,
        width: 0
    },
    getters: {
        getHeight(state) {
            return state.height;
        },
        getWidth(state) {
            return state.width;
        }
    },
    mutations: {
        setHeight(state, height) {
            state.height = height;
        },
        setWidth(state, width) {
            state.width = width;
        }
    },
    actions: {
        setHeight(state, height) {
            state.commit('setHeight', height);
        },
        setWidth(state, width) {
            state.commit('setWidth', width);
        }
    }
};
