<template>
    <div class="header">
        <div v-for="(item, index) in Object.keys(items)"
             :key="index"
             class="header__item"
        >
            {{ item }}: <b>{{ items[item] }}</b>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
.header {
    display: flex;
    justify-content: center;
    font-size: 120%;
    margin: 5px 0;
}

.header__item {
    display: inline-block;
    width: min-content;
    border-radius: .25rem;
    background-color: #6c757d;
    color: #FFFFFF;
    margin: 0 3px;

    white-space: nowrap;

    padding: .25em .4em;
    font-size: 75%;
    line-height: 1;
    text-align: center;
}

@media (max-width: 575px) {
    .header__item {
        font-size: 2.7vw;
    }
}
</style>
