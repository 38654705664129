import Vue from 'vue';

let route = 'auth/';

export default {
    login: params => Vue.axios.post(route + 'login', params)
        .then(
            result => result.token,
            reason => Promise.reject(reason)
        ),

    me: () => Vue.axios.get('auth/me')
        .catch(reason => Promise.reject(reason)),

    logout: () => Vue.axios.post('auth/logout')
        .catch(reason => Promise.reject(reason)),

    refresh: () => Vue.axios.post('auth/refresh')
        .then(
            value => value.token,
            reason => Promise.reject(reason)
        )
}
