import Vue from 'vue';

const roundsRepository = {
    /**
     * @return {Promise<T | never>}
     */
    getRounds: (params) => Vue.axios.get('rounds', {params})
        .then((response) => response, (error) => console.log(error)),

    /**
     * @parameters {object} params
     * @return {Promise<T | never>}
     */
    getDetailedRound: (partnerUid, bankId, gameId, number, playerId) => Vue.axios.get('detailedRound', {params: {partnerUid, bankId, gameId, number, playerId}})
        .then((response) => response, (error) => console.log(error)),
};

export default roundsRepository;
