<template>
    <b-field
        expanded
        label="Player"
        label-position="on-border"
    >
        <b-autocomplete
            v-model="searchPlayerValue"
            :custom-formatter="formatAutocompleteDisplay"
            :data="players"
            :open-on-focus="true"
            expanded
            placeholder="Search player"
            @select="selectPlayer"

            @typing="debouncedPlayerSearch"
        >
            <template slot-scope="{option}">
                <p class="title is-7">
                    {{ option.player_id }} (Partner: {{ option.partner_uid }} / Bank: {{ option.bank_id }})
                </p>
            </template>
        </b-autocomplete>
        <p class="control">
            <b-tooltip label="Clear input">
                <button
                    class="button is-danger"
                    @click="unselectPlayer()"
                >
                    <b-icon icon="delete"/>
                </button>
            </b-tooltip>
        </p>
    </b-field>
</template>

<script>
import {mapActions} from 'vuex';
import {debounce} from 'lodash';
import backofficeRepository from '../../repository/backofficeRepository';

export default {
    props: {
        selectedPlayer: {
            type: Object
        },
        selectedBank: {
            type: Object
        },
        selectedPartner: {
            type: Object
        },
    },
    data() {
        return {
            players: [],
            searchPlayerValue: ''
        }
    },
    computed: {
        player: {
            get() {
                return this.selectedPlayer;
            },
            set(value) {
                this.$emit('update:selectedPlayer', value);
                this.$emit('change');
            }
        },
        debouncedPlayerSearch() {
            return debounce(this.fetchData, 750);
        },
    },
    methods: {
        ...mapActions('loading', {
            startLoading: 'startLoading',
            stopLoading: 'stopLoading'
        }),
        getParams() {
            let params = {};

            if (this.selectedPartner) {
                params.partnerUid = this.selectedPartner.partner_uid;
            }

            if (this.selectedBank) {
                params.bankId = this.selectedBank.id;
            }

            if (this.searchPlayerValue) {
                params.search = this.searchPlayerValue;
            }

            return params;
        },
        async fetchData() {
            this.startLoading();

            return backofficeRepository.getPlayers(this.getParams())
                .then(response => this.players = response)
                .finally(() => this.stopLoading());
        },
        unselectPlayer() {
            this.player = null;
            this.searchPlayerValue = '';
        },
        formatAutocompleteDisplay(player) {
            return `${player.player_id} (Partner: ${player.partner_uid} / Bank: ${player.bank_id})`;
        },
        selectPlayer(activePlayer) {
            this.player = activePlayer;
        },
    },
    watch: {
        selectedPartner() {
            this.unselectPlayer();
            this.fetchData();
        },
        selectedBank() {
            this.unselectPlayer();
            this.fetchData();
        }
    },
    mounted() {
        this.fetchData();
    }
}
</script>

<style scoped>

</style>
