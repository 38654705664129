import axios from 'axios'
import config from '@/configs/axiosConfig'

let _axios = axios.create(config);

_axios.interceptors.request.use(
    // Do something before request is sent
    cfg => config.onRequest(cfg),
    // Do something with request error
    error => Promise.reject(error)
);

// Add a response interceptor
_axios.interceptors.response.use(
    // Do something with response data
    response => config.onResponse(response),
    // Do something with response error
    error => config.onError(error)
);

const axiosInstance = _axios;

// eslint-disable-next-line no-unused-vars
Plugin.install = (Vue) => {
    Vue.axios = axiosInstance;
    window.axios = axiosInstance;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return axiosInstance
            },
        },
        $axios: {
            get() {
                return axiosInstance
            },
        },
    })
};

export default Plugin
