import Vue from 'vue';

const backofficeRepository = {
    /**
     * @return {Promise<T | never>}
     */
    getPartners: () => Vue.axios.get('partners')
        .then((response) => response, (error) => console.log(error)),

    /**
     * @parameters {object} params
     * @return {Promise<T | never>}
     */
    getBanks: (params) => Vue.axios.get('banks', {params})
        .then((response) => response, (error) => console.log(error)),

    /**
     * @parameters {object} params
     * @return {Promise<T | never>}
     */
    getPlayers: (params) => Vue.axios.get('players', {params})
        .then((response) => response, (error) => console.log(error)),

    /**
     * @parameters {int} type
     * @return {Promise<T | never>}
     */
    getGames: (params) => Vue.axios.get('games', {params})
        .then((response) => response, (error) => console.log(error)),
};

export default backofficeRepository;
