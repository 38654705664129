<template>
    <div class="columns is-gapless is-multiline is-mobile" style="width: 100%">
        <div class="control column">
            <b-button :disabled="!previousAvailable" class="is-fullwidth arrow-button"
                      icon-right="arrow-left"

                      @click="onPreviousClicked()"
            />
        </div>

        <div v-if="isReplayAllowed"
             class="control column"
        >
            <b-button class="is-fullwidth arrow-button" icon-left="replay"
                      @click="onReplayClicked()"
            >
                Replay
            </b-button>
        </div>

        <div class="control column">
            <b-button :disabled="!nextAvailable" class="is-fullwidth arrow-button"
                      icon-left="arrow-right"

                      @click="onNextClicked()"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        nextAvailable: {
            type: Boolean,
            default: false,
        },
        previousAvailable: {
            type: Boolean,
            default: false,
        },
        isReplayAllowed: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        onPreviousClicked() {
            this.$emit('onPreviousClicked');
        },
        onNextClicked() {
            this.$emit('onNextClicked');
        },
        onReplayClicked() {
            this.$emit('onReplayClicked');
        }
    },
}
</script>

<style scoped>

</style>
