<template>
    <div class="content-page-main container is-fluid">
        <app-navbar v-if="isAuthenticated"/>

        <router-view/>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Navbar from "../components/Navbar";

export default {
    methods: {
        ...mapActions('auth', {
            setUser: 'setAuthUser',
            setToken: 'setBearerToken',
        })
    },
    computed: {
        ...mapGetters('auth', {
            isAuthenticated: 'isAuthenticated'
        })
    },
    components: {
        "app-navbar": Navbar
    },
    created() {
        let token = localStorage.getItem('token');

        if (token) {
            let user = localStorage.getItem('user');

            this.setUser(user);
            this.setToken(token);
        }
    }
}
</script>

<style scoped>
.content-page-main {
    margin-top: 1rem !important;
}
</style>
