<template>
    <b-table :current-page="currentPage"

             :data="spins" :per-page="perPage" :selected="row"

             :total="total" backend-pagination
             backend-sorting
             bordered
             hoverable

             narrowed
             paginated

             sort-icon="arrow-up"
             @sort="tableSortChanged"

             @page-change="pageChanged"
    >
        <template slot-scope="props">
            <b-table-column field="partner_uid" label="Partner"
                            sortable
            >
                {{ props.row.partner_uid }}
            </b-table-column>

            <b-table-column field="game_id" label="Game"
                            sortable
            >
                {{ props.row.game_title }}
            </b-table-column>

            <b-table-column field="bank_id" label="Bank"
                            sortable
            >
                {{ props.row.bank_id }} ({{ props.row.currency }})
            </b-table-column>

            <b-table-column field="player_id" label="Player"
                            sortable
            >
                {{ props.row.player_id }}
            </b-table-column>

            <b-table-column field="coin_stake_amount" label="Bet"
                            sortable
            >
                {{ fromCents(props.row.coin_stake_amount) }}

                <b-tag>{{ props.row.currency }}</b-tag>
            </b-table-column>

            <b-table-column field="coin_total_win_amount" label="Win"
                            sortable
            >
                {{ fromCents(props.row.coin_total_win_amount) }}

                <b-tag>{{ props.row.currency }}</b-tag>
            </b-table-column>

            <b-table-column field="total" label="Total"
                            sortable
            >
                {{ fromCents(props.row.total) }}

                <b-tag>{{ props.row.currency }}</b-tag>
            </b-table-column>

            <b-table-column field="total" label="Balance before"
                            sortable
            >
                {{ fromCents(props.row.balance_before) }}

                <b-tag>{{ props.row.currency }}</b-tag>
            </b-table-column>

            <b-table-column centered field="type" label="Type">
                <b-taglist attached class="is-centered">
                    <b-tag v-if="props.row.is_jackpot_spin" type="is-dark">
                        Jackpot
                    </b-tag>

                    <template v-if="isNotBaseSpin(props.row)">
                        <b-tag v-if="props.row.is_bonus_spin" type="is-success">
                            Bonus
                        </b-tag>

                        <b-tag v-if="props.row.is_free_spin" type="is-info">
                            Free
                        </b-tag>
                    </template>
                    <template v-else>
                        <b-tag type="is-light">
                            Base
                        </b-tag>
                    </template>

                    <b-tag v-if="props.row.is_daemon" type="is-warning">
                        Auto
                    </b-tag>

                    <b-tag v-if="props.row.is_end" type="is-primary">
                        End
                    </b-tag>
                    <b-tag v-else type="is-danger">
                        Playing
                    </b-tag>


                </b-taglist>
            </b-table-column>

            <b-table-column centered field="created_at"
                            label="Created"
                            sortable
            >
                {{ toLocalTime(props.row.created_at) }}
            </b-table-column>

            <b-table-column centered field="detailed" label="Detailed">
                <b-button size="is-small" type="is-danger"
                          @click="onDetailClicked(props.row.partner_uid, props.row.session_spin_id, props.row.session_id, props.index)"
                >
                    Details
                </b-button>
            </b-table-column>
        </template>

        <template v-if="!isDataEmpty" slot="bottom-left">
            <b-numberinput controls-position="compact"
                           max="50" min="10"

                           @input="perPageChange"
            />
        </template>

        <template slot="empty">
            <section class="section">
                <div class="content has-text-grey has-text-centered">
                    <p>
                        <b-icon
                            icon="emoticon-frown-outline"
                            size="is-large"
                        />
                    </p>
                    <p>No spins</p>
                </div>
            </section>
        </template>
    </b-table>
</template>

<script>
import {fromCents} from "@/helpers/balanceConverter";

export default {
    props: {
        spins: {
            required: true,
            type: Array,
            default() {
                return [
                    {
                        partner_uid: null,
                        session_id: null,
                        player_id: null,
                        bank_id: null,
                        game_id: null,
                        session_spin_id: null,
                        game_title: null,
                        coin_stake_amount: null,
                        coin_total_win_amount: null,
                        is_daemon: null,
                        is_bonus_spin: null,
                        is_free_spin: null,
                        is_jackpot_spin: null,
                        is_end: null,
                        created_at: null,
                        updated_at: null
                    }
                ];
            }
        },
        total: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        perPage: {
            type: Number,
            required: true,
        },
        selectedRow: {
            type: Object
        }
    },
    computed: {
        row: {
            set(value) {
                this.$emit('update:selectedRow', value);
            },
            get() {
                return this.selectedRow
            }
        },
        isDataEmpty() {
            return this.spins.length === 0;
        },
        totalStakes() {
            return this.spinsTotal.map(s => `${s.totalStake} <b-tag>${s.currency}</b-tag>`)
        }
    },
    methods: {
        fromCents,
        pageChanged(e) {
            this.$emit('page-change', e);
        },
        tableSortChanged(field, order) {
            this.$emit('sort', field, order);
        },
        perPageChange(perPage) {
            this.$emit('per-page-change', perPage)
        },
        onDetailClicked(partnerUid, sessionSpinId, sessionId, index) {
            this.$emit("onDetailClicked", partnerUid, sessionSpinId, sessionId, index);
        },
        isNotBaseSpin(spin) {
            return spin.is_bonus_spin || spin.is_free_spin;
        },
        toLocalTime(dateTime) {
            return window.moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm:ss');
        },
    }
};
</script>

<style scoped></style>
