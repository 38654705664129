<template>
    <b-field
        expanded
        label="Game"
        label-position="on-border"
    >
        <b-autocomplete
            v-model="searchGameValue"
            :custom-formatter="formatAutocompleteDisplay"
            :data="games"
            :open-on-focus="true"
            expanded
            placeholder="Search game"
            @select="selectGame"

            @typing="debouncedGameSearch"
        >
            <template slot-scope="{option}">
                <p class="title is-7">
                    {{ option.title }}
                </p>
            </template>
        </b-autocomplete>
        <p class="control">
            <b-tooltip label="Clear input">
                <button
                    class="button is-danger"
                    @click="unselectGame()"
                >
                    <b-icon icon="delete"/>
                </button>
            </b-tooltip>
        </p>
    </b-field>
</template>

<script>
import {mapActions} from "vuex";
import {debounce} from 'lodash';
import backofficeRepository from '../../repository/backofficeRepository';

export default {
    props: {
        selectedGame: {
            type: Object
        },
    },
    data() {
        return {
            games: [],
            searchGameValue: ''
        }
    },
    computed: {
        game: {
            get() {
                return this.selectedGame;
            },
            set(value) {
                this.$emit('update:selectedGame', value);
            }
        },
        debouncedGameSearch() {
            return debounce(this.fetchData, 750);
        },
    },
    methods: {
        ...mapActions('loading', {
            startLoading: 'startLoading',
            stopLoading: 'stopLoading'
        }),
        getParams() {
            let params = {};

            if (this.searchGameValue)
                params.search = this.searchGameValue;

            params.type = 1;

            return params;
        },
        fetchData() {
            this.startLoading();

            backofficeRepository.getGames(this.getParams())
                .then(response => this.games = response)
                .finally(() => this.stopLoading());
        },
        unselectGame() {
            this.game = null;
            this.searchGameValue = '';
        },
        formatAutocompleteDisplay(game) {
            return game.title;
        },
        selectGame(activeGame) {
            this.game = activeGame;
        },
    },
    mounted() {
        this.fetchData();
    }
}
</script>

<style scoped>

</style>
