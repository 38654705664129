<template>
    <section>
        <modal-header :items="gameHeader"/>
        <modal-header :items="playerPartnerHeader"/>
        <modal-header :items="bankSessionHeader"/>
        <modal-header :items="balanceRowHeader"/>
        <modal-header :items="betWinRowHeader"/>
        <modal-header :items="currentStateHeader"/>
    </section>
</template>

<script>
import ModalHeader from "../../ModalHeader.vue";

import {fromCents} from "@/helpers/balanceConverter";

export default {
    components: {ModalHeader},
    props: {
        playerId: {
            type: [String, Number],
            required: true,
        },
        sessionId: {
            type: [String, Number],
            required: true,
        },
        bankId: {
            type: [String, Number],
            required: true,
        },
        sessionSpinId: {
            type: [String, Number],
            required: true,
        },
        partnerUid: {
            type: String,
            required: true,
        },
        balanceBefore: {
            type: [String, Number],
            required: true
        },
        currency: {
            type: String,
            required: true
        },
        gameTitle: {
            type: String,
            required: true,
        },
        coinStakeAmount: {
            type: [String, Number],
            required: true
        },
        coinTotalWinAmount: {
            type: [String, Number],
            required: true
        },
        denom: {
            type: [String, Number],
            required: true
        },
        isEnd: {
            type: [Number, Boolean]
        }
    },
    computed: {
        gameHeader() {
            return {
                Game: this.gameTitle
            }
        },
        playerPartnerHeader() {
            return {
                Partner: this.partnerUid,
                Player: this.playerId,
            }
        },
        bankSessionHeader() {
            return {
                Bank: `${this.bankId} (${this.currency})`,
                Session: this.sessionId,
                SessionSpinId: this.sessionSpinId,
            }
        },
        balanceRowHeader() {
            return {
                "Balance Before": `${this.fromCents(this.balanceBefore)} ${this.currency}`,
            }
        },
        betWinRowHeader() {
            return {
                Bet: `${this.fromCents(this.coinStakeAmount)} ${this.currency}`,
                Win: `${this.fromCents(this.coinTotalWinAmount)} ${this.currency}`,
                Denom: this.denom
            }
        },
        currentStateHeader() {
            return {
                State: (this.isEnd === 0) ? 'Playing' : 'End'
            }
        },
    },
    methods: {
        fromCents
    }
}
</script>

<style scoped>

</style>
