import Vue from "vue";
import Vuex from 'vuex';
import workspace from "./modules/workspace"
import loading from "./modules/loading";
import auth from "./modules/auth";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        workspace,
        loading,
        auth
    },
    strict: process.env.NODE_ENV !== 'production'
})
