<template>
    <section>
        <realtime-game-details-modal ref="modal"

                                     :previous-available="isPreviousAvailable"
                                     :next-available="isNextAvailable"

                                     :partner-uid="this.partnerUid"
                                     :bank-id="this.bankId"
                                     :game-id="this.gameId"
                                     :number="this.number"
                                     :player-id="this.playerId"

                                     @onPreviousClicked="onPreviousClicked"
                                     @onNextClicked="onNextClicked"
        />

        <div class="box">
            <date-time-picker :selected-period.sync="period"/>

            <partner-select :selected-partner.sync="partner"
                            :required="false"
                            :allow-clear="true"
            />

            <bank-select :selected-bank.sync="bank"
                         :selected-partner="partner"
            />

            <realtime-game-auto-complete :selected-game.sync="game"
            />

            <b-field grouped group-multiline>
                <b-field>
                    <b-button type="is-success" @click="searchClicked">Search</b-button>
                </b-field>
            </b-field>
        </div>

        <div class="box">
            <rounds-table :rounds="rounds"

                          :current-page="pagination.currentPage"
                          :total="pagination.total"
                          :per-page="pagination.perPage"
                          :selected-row="selectedRow"

                          @page-change="pageChange"
                          @per-page-change="perPageChange"
                          @sort="sortChange"
                          @onDetailClicked="fetchAndOpenDetailedModalFromTable"
            />
        </div>
    </section>
</template>

<script>
import {mapActions} from 'vuex';
import roundsRepository from "../repository/roundsRepository";

import BankSelect from "../components/Filters/BankSelect"
import PartnerSelect from "../components/Filters/PartnerSelect";
import DateTimePicker from "../components/Filters/DateTimePicker";
import RoundsTable from "../components/Rounds/RoundsTable";
import RealtimeGameAutoComplete from "@/components/Filters/RealtimeGameAutoComplete.vue";
import RealtimeGameDetailsModal from "@/components/Rounds/RealtimeGameDetailsModal.vue";

export default {
    data() {
        return {
            partnerUid: undefined,
            bankId: undefined,
            gameId: undefined,
            number: undefined,
            playerId: undefined,

            bank: null,
            partner: null,
            player: null,
            game: null,

            active: false,
            selectedRowIndex: null,

            rounds: [],
            period: {
                fromTs: null,
                toTs: null,
            },
            tableFilters: {
                order: null,
                field: null
            },
            pagination: {
                perPage: 10,
                currentPage: 1,
                total: 0
            },
            showModal: false,
            roundData: {}
        }
    },
    computed: {
        paginationFilters() {
            return {
                perPage: this.pagination.perPage,
                page: this.pagination.currentPage,
            }
        },
        isNextAvailable() {
            let currentRowInTotal = (this.selectedRowIndex + 1) + (this.pagination.currentPage - 1) * this.pagination.perPage;

            return currentRowInTotal !== this.pagination.total;
        },
        isPreviousAvailable() {
            return !(this.pagination.currentPage === 1 && this.selectedRowIndex === 0);
        },
        formattedFormFilters() {
            let params = {};

            if (this.partner) {
                params.partnerUid = this.partner.partner_uid;
            }

            if (this.bank) {
                params.bankId = this.bank.id;
                params.partnerUid = this.bank.bank_partner_uid;
            }

            if (this.player) {
                params.playerId = this.player.player_id;
                params.partnerUid = this.player.partner_uid;
                params.bankId = this.player.bank_id;
            }

            if (this.game)
                params.gameId = this.game.id;

            params.from = this.period.fromTs;
            params.to = this.period.toTs;

            return params;
        },
        selectedRow() {
            return this.selectedRowIndex === null ? null : this.rounds[this.selectedRowIndex];
        }
    },
    methods: {
        ...mapActions('loading', {
            startLoading: 'startLoading',
            stopLoading: 'stopLoading'
        }),
        getCurrentRound() {
            return this.rounds[this.selectedRowIndex];
        },
        async fetchRounds() {
            this.startLoading();

            roundsRepository.getRounds(this.getFilters())
                .then((response) => {
                        this.pagination = response.pagination;
                        this.rounds = response.data;
                    }
                ).finally(() => this.stopLoading());
        },
        openModal() {
            const currentRound = this.getCurrentRound();

            this.fetchAndOpenDetailedModalFromTable(currentRound.partner_uid, currentRound.bank_id, currentRound.game_id, currentRound.number, this.selectedRowIndex, currentRound.playerId);
        },
        async incrementCurrentRound() {
            if ((this.selectedRowIndex + 1) === this.pagination.perPage) {
                this.pagination.currentPage++;
                await this.fetchRounds();
                this.selectedRowIndex = 0;
            } else {
                this.selectedRowIndex++;
            }
        },
        async decrementCurrentRound() {
            if (this.selectedRowIndex === 0) {
                this.pagination.currentPage--;
                await this.fetchRounds();
                this.selectedRowIndex = this.pagination.perPage - 1;
            } else {
                this.selectedRowIndex--;
            }
        },
        openDetailed() {
            this.$refs.modal.openModal();
        },
        openSpaDetailed(roundData) {
            this.$refs.spaModal.openModal(roundData);
        },
        fetchAndOpenDetailedModalFromTable(partnerUid, bankId, gameId, number, index, playerId) {
            this.selectedRowIndex = index;

            this.partnerUid = partnerUid;
            this.bankId = bankId;
            this.gameId = gameId;
            this.number = number;
            this.playerId = playerId;

            this.openDetailed();
        },
        pageChange(page) {
            this.pagination.currentPage = page;

            this.fetchRounds();
        },
        searchClicked() {
            this.pagination.currentPage = 1;

            this.fetchRounds();
        },
        perPageChange(perPage) {
            if (perPage > 50) {
                perPage = 50;
            } else if (perPage < 10) {
                perPage = 10;
            }

            this.pagination.perPage = perPage;
        },
        sortChange(sortBy, sortDesc) {
            this.pagination.currentPage = 1;

            this.tableFilters.sortBy = sortBy;
            this.tableFilters.sortDesc = sortDesc;

            this.fetchRounds();
        },
        getFilters() {
            return {
                ...this.tableFilters,
                ...this.paginationFilters,
                ...this.formattedFormFilters,
            };
        },
        async onPreviousClicked() {
            await this.decrementCurrentRound();
            this.openModal();
        },
        async onNextClicked() {
            await this.incrementCurrentRound();
            this.openModal();
        }
    },
    created() {
        this.period = {
            fromTs: window.moment().startOf('day').unix(),
            toTs: window.moment().endOf('day').unix()
        };
        this.fetchRounds();
    },
    components: {
        RealtimeGameDetailsModal,
        RoundsTable,
        DateTimePicker,
        PartnerSelect,
        RealtimeGameAutoComplete,
        BankSelect,
    },
}
</script>
