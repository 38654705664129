<template>
    <b-field
        :type="selectType"
        expanded
        label="Partner"
        label-position="on-border"
    >
        <b-select v-model="partner"
                  expanded
                  placeholder="Choose partner"
        >
            <option
                v-for="partner in partners"
                :key="partner.id"
                :value="partner"
            >
                {{ partner.partner_uid }}
            </option>
        </b-select>
        <p v-if="allowClear" class="control">
            <b-tooltip label="Clear input">
                <button
                    class="button is-danger"
                    @click="unselectPartner"
                >
                    <b-icon icon="delete"/>
                </button>
            </b-tooltip>
        </p>
    </b-field>
</template>

<script>
import {mapActions} from "vuex";
import backofficeRepository from '../../repository/backofficeRepository';

export default {
    props: {
        selectedPartner: {
            type: Object
        },
        allowClear: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            partners: [],
        }
    },
    computed: {
        partner: {
            get() {
                return this.selectedPartner;
            },
            set(value) {
                this.$emit('update:selectedPartner', value);
            }
        },
        selectType() {
            if (this.required && this.partner === null) {
                return 'is-danger'
            }

            return 'is-default';
        }
    },
    methods: {
        ...mapActions('loading', {
            startLoading: 'startLoading',
            stopLoading: 'stopLoading'
        }),
        fetchData() {
            this.startLoading();

            backofficeRepository.getPartners()
                .then(response => this.partners = response)
                .finally(() => this.stopLoading());
        },
        unselectPartner() {
            this.partner = null;
        }
    },
    mounted() {
        this.fetchData();
    }
}
</script>

<style scoped>

</style>
