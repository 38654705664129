<template>
    <component :is="gameModal"
               v-if="gameId"

               :round-data="roundData"
               :tickets="tickets"
    />
</template>

<script>
import {getGameModal} from "@/helpers/realtimeGameModals";

export default {
    props: {
        gameId: {
            type: Number,
            required: true
        },
        roundData: {
            type: [Object],
            required: true,
        },
        tickets: {
            type: Array,
            required: true,
            default() {
                return []
            },

        }
    },
    methods: {
        getGameModal
    },
    computed: {
        gameModal() {
            return this.getGameModal(this.gameId);
        },
    }
}
</script>

<style scoped>

</style>
