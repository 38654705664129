<template>
    <section class="section">
        <div class="card modal-card login__form">
            <header class="modal-card-head">
                <p class="modal-card-title">Login</p>
            </header>
            <section class="modal-card-body">
                <b-field label="Email">
                    <b-input
                        v-model="username"
                        placeholder="Your login"
                        type="text"

                        @keypress.enter.native="onSubmit"
                    >
                    </b-input>
                </b-field>

                <b-field label="Password">
                    <b-input
                        v-model="password"
                        password-reveal
                        placeholder="Your password"
                        type="password"

                        @keypress.enter.native="onSubmit"
                    >
                    </b-input>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-primary" @click.prevent="onSubmit">Login</button>
            </footer>
        </div>
    </section>

</template>

<script>
import {mapActions} from 'vuex';

export default {
    data() {
        return {
            // username: null,
            // password: null,
            username: 'admin',
            password: 'password'
        }
    },
    methods: {
        ...mapActions('auth', {
            login: 'login'
        }),
        ...mapActions('loading', {
            startLoading: 'startLoading',
            stopLoading: 'stopLoading'
        }),
        onSubmit() {
            this.startLoading();

            this.login({username: this.username, password: this.password})
                .then(() => this.$buefy.snackbar.open({message: 'Successful login', type: "is-success"}))
                .then(() => this.$router.push('/'))
                .finally(() => this.stopLoading())
        }
    }
}
</script>

<style scoped>

.login__form {
    max-width: 320px;
}

@media (min-width: 768px) {
    .login__form {
        max-width: 500px;
    }
}
</style>
