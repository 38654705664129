<template>
    <b-table :current-page="currentPage"

             :data="rounds" :per-page="perPage" :selected="row"

             :total="total" backend-pagination
             backend-sorting
             bordered
             hoverable

             narrowed
             paginated

             sort-icon="arrow-up"
             @sort="tableSortChanged"

             @page-change="pageChanged"
    >
        <template slot-scope="props">
            <b-table-column field="number" label="Number"
                            sortable
            >
                {{ props.row.number }}
            </b-table-column>

            <b-table-column field="partner_uid" label="Partner"
                            sortable
            >
                {{ props.row.partner_uid }}
            </b-table-column>

            <b-table-column field="game_id" label="Game"
                            sortable
            >
                {{ props.row.game_title }}
            </b-table-column>

            <b-table-column field="bank_id" label="Bank"
                            sortable
            >
                {{ props.row.bank_id }} ({{ props.row.currency }})
            </b-table-column>

            <b-table-column field="bet" label="Bet"
                            sortable
            >
                {{ fromCents(props.row.bet) }}

                <b-tag>{{ props.row.currency }}</b-tag>
            </b-table-column>

            <b-table-column field="win" label="Win"
                            sortable
            >
                {{ fromCents(props.row.win) }}

                <b-tag>{{ props.row.currency }}</b-tag>
            </b-table-column>

            <b-table-column field="total" label="Total"
                            sortable
            >
                {{ fromCents(props.row.total) }}

                <b-tag>{{ props.row.currency }}</b-tag>
            </b-table-column>

            <b-table-column centered field="started_at"
                            label="Started"
                            sortable
            >
                {{ toLocalTime(props.row.started_at) }}
            </b-table-column>

            <b-table-column centered field="ended_at"
                            label="Ended"
                            sortable
            >
                {{ toLocalTime(props.row.ended_at) }}
            </b-table-column>

            <b-table-column centered field="detailed" label="Detailed">
                <b-button size="is-small" type="is-danger"
                          @click="onDetailClicked(props.row.partner_uid, props.row.bank_id, props.row.game_id, props.row.number, props.index, props.row.player_id)"
                >
                    Details
                </b-button>
            </b-table-column>
        </template>

        <template v-if="!isDataEmpty" slot="bottom-left">
            <b-numberinput controls-position="compact"
                           max="50" min="10"

                           @input="perPageChange"
            />
        </template>

        <template slot="empty">
            <section class="section">
                <div class="content has-text-grey has-text-centered">
                    <p>
                        <b-icon
                            icon="emoticon-frown-outline"
                            size="is-large"
                        />
                    </p>
                    <p>No rounds</p>
                </div>
            </section>
        </template>
    </b-table>
</template>

<script>
import {fromCents} from "@/helpers/balanceConverter";

export default {
    props: {
        rounds: {
            required: true,
            type: Array,
            default() {
                return [
                    {
                        number: null,
                        partner_uid: null,
                        bank_id: null,
                        game_id: null,
                        game_title: null,
                        bet: null,
                        win: null,
                        total: null,
                        started_at: null,
                        ended_at: null
                    }
                ];
            }
        },
        total: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        perPage: {
            type: Number,
            required: true,
        },
        selectedRow: {
            type: Object
        }
    },
    computed: {
        row: {
            set(value) {
                this.$emit('update:selectedRow', value);
            },
            get() {
                return this.selectedRow
            }
        },
        isDataEmpty() {
            return this.rounds.length === 0;
        },
        totalStakes() {
            return this.roundsTotal.map(s => `${s.totalStake} <b-tag>${s.currency}</b-tag>`)
        }
    },
    methods: {
        fromCents,
        pageChanged(e) {
            this.$emit('page-change', e);
        },
        tableSortChanged(field, order) {
            this.$emit('sort', field, order);
        },
        perPageChange(perPage) {
            this.$emit('per-page-change', perPage)
        },
        onDetailClicked(partnerUid, bankId, gameId, number, index, player_id = null) {
            this.$emit("onDetailClicked", partnerUid, bankId, gameId, number, index, player_id);
        },
        toLocalTime(dateTime) {
            return window.moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm:ss');
        },
    }
};
</script>

<style scoped></style>
