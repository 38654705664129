const buefyConfig = {
    defaultIconPack: 'mdi',
    defaultSnackbarDuration: 3500,
    defaultToastDuration: 2000,
    defaultNotificationDuration: 2000,
    defaultTooltipType: 'is-primary',
    defaultTooltipAnimated: true,
    defaultTooltipDelay: 0,
    defaultInputAutocomplete: 'on',
    defaultModalCanCancel: ['escape', 'x', 'outside', 'button'],
    defaultDatepickerMobileNative: true,
    defaultTimepickerMobileNative: true,
    defaultNoticeQueue: false,
    defaultInputHasCounter: true,
    defaultUseHtml5Validation: true,
    defaultDropdownMobileModal: true,
    defaultDatepickerYearsRange: [-100, 3],
    defaultDatepickerNearbyMonthDays: true,
    defaultDatepickerNearbySelectableMonthDays: false,
};

export default buefyConfig
