import Vue from 'vue'
import App from './App.vue'
import axios from './plugins/axios'
import Buefy from 'buefy'
import buefyConfig from "./configs/buefyConfig";
import {router} from './routes.js';
import lodash from 'lodash'
import store from './store/store';
import '@/assets/scss/app.scss';
import '@mdi/font/css/materialdesignicons.css'
import '@mdi/font/scss/materialdesignicons.scss'
import resize from 'vue-resize-directive'


import 'vue-datetime-range-picker/dist/vue-datetime-range-picker.css';

Vue.use(Buefy, buefyConfig);
Vue.use(axios);

window.moment = require('moment');

Vue.prototype._ = lodash;

Vue.directive('resize', resize);
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});
