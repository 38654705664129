<template>
    <section>
        <header class="header">
            <game-details-header :partner-uid="spinData.partner_uid"
                                 :session-id="spinData.session_id"
                                 :session-spin-id="spinData.session_spin_id"
            />
        </header>
        <section class="overflow">
            <game-details-body-headers :balance-before="spinData.balance_before"
                                       :bank-id="spinData.bank_id"
                                       :coin-stake-amount="spinData.coin_stake_amount"

                                       :coin-total-win-amount="spinData.coin_total_win_amount"
                                       :currency="spinData.currency"
                                       :denom="spinData.denom"

                                       :game-title="spinData.game_title"
                                       :partner-uid="spinData.partner_uid"
                                       :player-id="spinData.player_id"

                                       :session-id="spinData.session_id"
                                       :session-spin-id="spinData.session_spin_id"

                                       :is-end="spinData.is_end"
            />

            <game-details-body :game-id="spinData.game_id"
                               :state-data="parsedStateData.data"
            />
        </section>
        <footer class="footer">
            <game-details-footer :is-replay-allowed="spinData.spin_step_id"
                                 :next-available="nextAvailable"
                                 :previous-available="previousAvailable"

                                 @onPreviousClicked="$emit('onPreviousClicked')"
                                 @onNextClicked="$emit('onNextClicked')"
            />
        </footer>
    </section>
</template>

<script>
import GameDetailsHeader from "./SlotGameDetailsHeader";
import GameDetailsBodyHeaders from "./SlotGameDetailsBodyHeaders";
import GameDetailsFooter from "./SlotGameDetailsFooter";
import GameDetailsBody from "./SlotGameDetailsBody";

export default {
    props: {
        spinData: {
            type: Object,
            default() {
                return {
                    id: undefined,
                    game_title: undefined,
                    game_id: undefined,
                    player_id: undefined,
                    bank_id: undefined,
                    currency: undefined,
                    bank_desc: undefined,
                    spin_data: undefined,
                    spin_step_id: undefined,
                    state_data: undefined,
                    coin_stake_amount: undefined,
                    coin_total_win_amount: undefined,
                    denom: undefined,
                    balance_before: undefined,
                    is_daemon: undefined,
                    is_end: undefined,
                    is_bonus_spin: undefined,
                    is_free_spin: undefined,
                    created_at: undefined,
                }
            }
        },
        nextAvailable: {
            type: Boolean,
            default: false,
        },
        previousAvailable: {
            type: Boolean,
            required: false,
        }
    },
    computed: {
        parsedStateData() {
            return JSON.parse(this.spinData.state_data);
        },
    },
    components: {
        GameDetailsHeader,
        GameDetailsBodyHeaders,
        GameDetailsBody,
        GameDetailsFooter
    }
}
</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb;
}

.overflow {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    max-height: calc(100vh - 110px);
    background-color: #FFF;
}

.footer {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 50px;
    background-color: #f5f5f5;
    border-top: 1px solid #dbdbdb;
}
</style>
