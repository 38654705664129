<template>
    <b-field class="copy-text" style="width: 100%">
        <b-input ref="spinUid" :value="title" class="spinUid" expanded readonly/>
        <p class="control">
            <b-button class="copy-button" type="is-info" @click="copySpinUid">Copy</b-button>
        </p>
    </b-field>
</template>

<script>

export default {
    props: {
        partnerUid: {
            type: String,
            required: true,
        },
        sessionId: {
            type: [String, Number],
            required: true,
        },
        sessionSpinId: {
            type: [String, Number],
            required: true,
        }
    },
    computed: {
        title() {
            return `${this.partnerUid}-${this.sessionId}-${this.sessionSpinId}`;
        },
    },
    methods: {
        copySpinUid() {
            this.$refs.spinUid.$el.querySelector('input').select();
            document.execCommand('copy');
            this.$buefy.snackbar.open('SpinUid copied to clipboard');
        },
    }
}
</script>
