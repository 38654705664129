<template>
    <component :is="gameModal"
               v-if="gameId"

               :state-data="stateData"
    />
</template>

<script>
import {getGameModal} from "@/helpers/slotGameModals";

export default {
    props: {
        gameId: {
            type: Number,
            required: true
        },
        stateData: {
            type: Object,
            required: true
        }
    },
    methods: {
        getGameModal
    },
    computed: {
        gameModal() {
            return this.getGameModal(this.gameId);
        },
    }
}
</script>

<style scoped>

</style>
