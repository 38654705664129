<template>
    <div class="app">
        <b-loading :is-full-page="true"
                   :active.sync="getLoading"
        />

        <router-view/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters('loading', [
            'getLoading',
        ]),
    },
}
</script>

<style>
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

</style>
