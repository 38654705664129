export default {
    namespaced: true,
    state: {
        loading: false,
        loadingCount: 0
    },
    getters: {
        getLoading(state) {
            return state.loading;
        }
    },
    mutations: {
        startLoading(state) {
            state.loadingCount++;

            if (state.loadingCount === 1)
                state.loading = true;
        },
        stopLoading(state) {
            if (state.loadingCount > 0)
                state.loadingCount--;

            if (state.loadingCount === 0)
                state.loading = false;
        }
    },
    actions: {
        startLoading(state) {
            state.commit('startLoading');
        },
        stopLoading(state) {
            state.commit('stopLoading');
        }
    }
};
