const gamesModals = {
    1: 'Slots/DolphinsPearlModal',
    2: 'Slots/BookOfRaDeluxeModal',
    3: 'Slots/JustJewelsModal',
    4: 'Slots/XtraHotModal',
    5: 'Lotto/LuckyLadyCharmLottoModal',
    6: 'Lotto/TheMoneyGameLottoModal',
    7: 'Slots/PlentyOnTwentyModal',
    8: 'Slots/ColumbusDeluxeModal',
    9: 'Lotto/DolphinsPearlLottoModal',
    10: 'Bingo/Bingo37/Bingo37Modal',
    11: 'Keno/Keno/KenoModal',
    12: 'Slots/LuckyLadyCharmModal',
    13: 'Slots/FaustModal',
    14: 'Slots/LordOfTheOceanModal',
    15: 'Slots/BookOfRaClassicModal',
    16: 'Slots/SizzlingHotModal',
    17: 'Slots/TheFruitinatorModal',
    18: 'Slots/HotFrootasticModal',
    19: 'Slots/DragonsTreasureModal',
    20: 'Slots/EyeOfHorusModal',
    21: 'Slots/FishinFrenzyModal',
    22: 'Slots/CairoCasinoModal',
    23: 'Slots/MagicMirrorDeluxe2Modal',
    24: 'Slots/Halloween3DModal',
    25: 'Slots/ExtraWildModal',
    26: 'Poker/WindJammer2/WindJammer2Modal',
    27: 'Slots/ElDuendeDeLaSuerteModal',
    28: 'Slots/WizardModal',
    29: 'Slots/BillyJoeModal',
    30: 'Slots/MagicForestModal',
    31: 'Slots/CrazyFarmModal',
    32: 'Slots/AztecGoldModal',
    33: 'Slots/FruitCocktailModal',
    34: 'Blackjack/RoyalCrownBlackjack/RoyalCrownBlackjackModal',
    35: 'Poker/MagicPoker/MagicPokerModal',
    36: 'Poker/BluesPoker/BluesPokerModal',
    37: 'Keno/EgyptKeno/EgyptKenoModal',
    38: 'Keno/PickKeno/PickKenoModal',
    39: 'Slots/CrazyMonkeyModal',
    40: 'Slots/LuckyHaunterModal',
    41: 'Slots/GarageModal',
    42: 'Slots/ResidentModal',
    43: 'Slots/RockClimberModal',
    44: 'Slots/PirateModal',
    45: 'Slots/GnomeModal',
    46: 'Slots/IslandModal',
    47: 'Slots/KeksModal',
    48: 'Slots/MagicBookModal',
    49: 'Slots/BookOfRomeoAndJuliaModal',
    50: 'Slots/CrystalBallModal',
    51: 'Slots/RamsesBookModal',
    52: 'Slots/FenixPlayDeluxeModal',
    53: 'Slots/FenixPlay27DeluxeModal',
    54: 'Slots/MagicFruitsDeluxeModal',
    55: 'Slots/MagicFruits4DeluxeModal',
    56: 'Slots/MagicFruits27Modal',
    57: 'Slots/FenixPlay27Modal',
    58: 'Slots/VegasHotModal',
    59: 'Slots/MagicHotModal',
    60: 'Slots/BlackHawkModal',
    61: 'Slots/MagicOfTheRingModal',
    62: 'Slots/MagicTargetModal',
    63: 'Slots/MagicTargetDeluxeModal',
    64: 'Slots/TetriManiaModal', // Нет ресурсов
    // 65: 'Slots/LuckyFishModal', // Нет ресурсов
    66: 'Slots/FruitsAndDiamondsModal',
    // 67: 'Slots/LarryTheLeprechaunModal', // Нет ресурсов
    68: 'Slots/SupraHotModal',
    69: 'Poker/JokerWild/JokerWildModal',
    70: 'Slots/FancyFruitsModal',
    71: 'Slots/BooksAndBullsModal', // Нет ресурсов
    72: 'Slots/RomanLegionModal',
    73: 'Slots/ExplodiacModal',
    74: 'Bingo/Bingo37English/Bingo37EnglishModal',
    75: 'Slots/MagicMonkRasputinModal',
    76: 'Slots/VegasSlotModal',
    77: 'Blackjack/Blackjack21/Blackjack21Modal',
    78: 'Slots/DesertBookDeluxeModal',
    80: 'Slots/LoveStoryModal',
    81: 'Slots/PirateIslandModal',
    82: 'Slots/MysteriousModal',
    83: 'Slots/DragonCastleModal',
    84: 'Slots/EyeOfRaModal',
    89: 'Slots/BerriesModal',
    90: 'Slots/JewelsStoneModal',
    91: 'Slots/OceaniaModal',
    98: 'Slots/BookOfPersiaModal',
    99: 'Slots/HotSlotModal',
    100: 'Slots/PowerStarsModal',
    101: 'Slots/QueenOfHeartsDeluxeModal',
    102: 'Slots/BlazingStarModal',
    103: 'Slots/RainbowReelsModal',
    105: 'Slots/DoubleTripleChanceModal',
    106: 'Slots/GoldOfPersiaModal',
    107: 'Slots/JollysCapModal',
    108: 'Slots/ElToreroModal',
    109: 'Slots/MissKittyModal',
    110: 'Slots/50LionsModal',
    111: 'Slots/TheAlchemistModal',
    112: 'Slots/GoldenSevensModal',
    113: 'Slots/JokerMillionsModal',
    114: 'Slots/HolmesAndTheStolenStones',
    116: 'Slots/BuffaloModal',
    118: 'Bingo/Bingo37/Bingo37Modal',
    120: 'Slots/BloodSuckersModal',
    121: 'Slots/FruitCaseModal',
    122: 'Slots/LightsModal',
    123: 'Slots/MagicPortalsModal',
    124: 'Slots/SecretOfTheStones',
    126: 'Slots/VictoriousModal',
    128: 'Slots/StarburstModal',
    129: 'Slots/DesertOfRaModal',
    131: 'Slots/LuckyDiamondSevenModal',
    130: 'Slots/OceansPearlModal',
    132: 'Slots/TreasureOfOcean',
    133: 'Slots/LadysThunderballModal',
    134: 'Slots/FireStarsModal',
    135: 'Slots/LuckyPharaohModal',
    136: 'Slots/ElDuendoDeluxeModal',
    137: 'Slots/SizzlingFruitsModal',
    139: 'Slots/FruitManiaModal',
    140: 'Slots/50DragonsModal',
    141: 'Slots/ReelRushModal',
    142: 'Slots/FlowersModal',
    149: 'Slots/BuffaloKingModal',
    150: 'Slots/AlmightyRamses2Modal',
    151: 'Slots/30SpicyFruitsModal',
    153: 'Slots/WildPandaModal',
    154: 'Slots/Halloween3DModal',
    155: 'Slots/WizardModal',
    156: 'Poker/Poker21/Poker21Modal',
    160: 'Slots/Halloween6Modal',
    162: 'Slots/BookOfRaDeluxe6Modal',
    163: 'Slots/TizonaModal',
    164: 'Slots/BakerStreet221bModal',
    165: 'Slots/GhostSliderModal',
    166: 'Slots/FruitsManiaModal',
    167: 'Slots/HappyHalloweenModal',
    168: 'Slots/ThePigWizardModal',
    169: 'Slots/RoyalSevenModal',
    170: 'Slots/BookOfDeadModal',
    171: 'Slots/KingOfCardsModal',
    172: 'Slots/BigBuckBunnyModal',
    174: 'Slots/HideAndSeekModal',
    175: 'Slots/AgentIstanbulModal',
    176: 'Slots/IndianRubyModal',
    177: 'Slots/ScribesOfThebesModal',
    178: 'Slots/SuperDupperCherryModal',
    179: 'Slots/ElDuendeDeLaSuerteModal',
    180: 'Slots/WizardModal',
    181: 'Slots/Halloween3DModal',
    182: 'Slots/CrazyFarmModal',
    183: 'Slots/BillyJoeModal',
    184: 'Slots/MagicForestModal',
    185: 'Mini/Hilo/HiloModal',
    186: 'Mini/MiniRoulette/MiniRouletteModal',
    187: 'Mini/Dice/DiceModal',
    188: 'Mini/Mines/MinesModal',
    189: 'Mini/MiniKeno/MiniKenoModal',
    190: 'Mini/Goal/GoalModal',
    191: 'Mini/Plinko/PlinkoModal',
    192: 'Slots/PiratesTreasureModal',
    193: 'Slots/CasinoDeluxeModal',
    194: 'Slots/TheGoldenMineModal',
    195: 'Poker/MagicPoker/MagicPokerModal',
    196: 'Poker/BluesPoker/BluesPokerModal',
    197: 'Keno/EgyptKenoSpain/EgyptKenoModal',
    198: 'Slots/FruitsAndDiamondsSpainModal',
    199: 'Poker/Poker21/Poker21Modal',
    202: 'Slots/AmazonsBattleModal',
    203: 'Slots/50AmazonsBattleModal',
    204: 'Slots/SweetBonanzaModal',
    205: 'Mini/Hotline/HotlineModal',
    206: 'Slots/5FlaringFruitsModal',
    207: 'Slots/50FlaringFruitsModal',
    208: 'Slots/100FlaringFruitsModal',
    209: 'Slots/NordicFireModal',
    210: 'Slots/AlexandriaFireModal',
    211: 'Slots/DivineFireModal',
    212: 'Slots/ElDuendeDeLaSuerteModal',
    213: 'Slots/WizardModal',
    214: 'Slots/Halloween3DModal',
    215: 'Poker/MagicPoker/MagicPokerModal',
    216: 'Bingo/Bingo37/Bingo37Modal',
    222: 'Slots/UltraHotDeluxeModal',
    224: 'Slots/TempleOfGoldModal',
};

export const getGameModal = gameId => {
    let gamesModal = gamesModals[gameId];

    if (gamesModal) {
        return () => import(`@/components/Spins/Games/${gamesModal}`);
    } else {
        return () => import(`@/components/Spins/Games/UnknownModal`);
    }
};

export default { getGameModal };
